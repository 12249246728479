import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { generatePath } from '@/aggregator/paths/helpers';
import { PathPrivate } from '@/aggregator/paths/constants';
import { Config } from '@/aggregator/config/env';
import * as auth from '@/aggregator/config/auth';

type ActionsMenuPropsType = {
  dataIndex: number;
  uuid: string;
};

export function ActionsMenu(props: ActionsMenuPropsType) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuRowIndex, setMenuRowIndex] = React.useState<number | null>(null);

  const isFleetManager = auth.isFleetManager();

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuRowIndex(index);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
    setMenuRowIndex(null);
  };

  const handleOnClickItem = (path: string, isExternal = false) => {
    handleOnClose();
    if (isExternal) {
      const url = path;
      const newTab = document.createElement('a');
      newTab.href = url;
      newTab.target = '_blank';
      newTab.rel = 'noopener noreferrer';
      newTab.click();
    } else {
      const pathUpdated = generatePath(path as PathPrivate, {
        carrierId: props.uuid,
      });
      navigate(pathUpdated);
    }
  };

  const options = [
    { name: 'Set Carrier Fees', path: PathPrivate.CarrierMarketingFees },
    { name: 'Edit Carrier', path: PathPrivate.CarrierEdit },
    { name: 'Drivers', path: PathPrivate.CarrierDrivers },
    { name: 'Credit Line', path: PathPrivate.CarrierCreditLine },
    { name: 'Prompts', path: PathPrivate.CarrierPrompts },
    { name: 'Limits', path: PathPrivate.CarrierLimits },
    { name: 'One Time Limit', path: PathPrivate.OneTimeLimit },
    { name: 'Book Payment', path: PathPrivate.CarrierBookPayment },
    { name: 'Payment History', path: PathPrivate.CarrierPaymentHistory },
    { name: 'Readiness', path: PathPrivate.CarrierReadiness },
    {
      name: 'Export Transactions',
      path: PathPrivate.CarrierExportTransactions,
    },
    { name: 'FleetApp Users', path: PathPrivate.CarrierFleetAppUsers },
    {
      name: 'Go to Fleet App',
      path: `${Config.FLEET_APP_URL}/carriers/${props.uuid}/drivers`,
      isExternal: true,
      hidden: !isFleetManager,
    },
  ];

  return (
    <div>
      <IconButton
        id="long-button"
        disabled={false}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(event) => handleOnClick(event, props.dataIndex)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && menuRowIndex === props.dataIndex}
        onClose={handleOnClose}
      >
        {options.map((option) => {
          if (option.hidden) return;
          return (
            <MenuItem
              key={option.name}
              onClick={() => handleOnClickItem(option.path, option.isExternal)}
            >
              {option.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
